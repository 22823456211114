<style lang="less" scoped>
	@import "../assets/css/variables";
    /*进度条*/
    progress {
      display: inline-block;
      width: 100%;
      height: 8px;
      background-color:#e6e6e6;
      color: #0064B4; /*IE10*/
    }

    .showTip {
      margin-top: -76px;
      height: 475px;
      padding-top: 100px;
      width: 100%;
      text-align: center;
      color: #999;
      background-color: rgb(238,243,250);
      background-image: url(../assets/images/no-data.png);
      background-position: center center;
      background-repeat: no-repeat;
    }

    progress::-moz-progress-bar { background: #0064B4; }
    progress::-webkit-progress-bar { background: #e6e6e6; }
    progress::-webkit-progress-value  { background: rgb(73,169,238); }

  .card-font {
    font-size: 12px;
    color: #666;
  }

  .font-24 {
    font-size: 14px;
    color: #666;
  }

  .card-arrow-red {
    position: relative;
    top: 8px;
    border: 4px solid transparent;
    border-top-color: #f00;
    margin-right: 5px;
  }

  .card-arrow-green {
    position: relative;
    top: -8px;
    border: 4px solid transparent;
    border-bottom-color: #0f0;
    margin-right: 5px;
  }

  .title {
    font-size: 16px;
    color: #666;
    padding: 12px;
    border-bottom: 1px solid rgb(235, 235, 235);
    margin-bottom: 20px;
  }

  .page-container {
    overflow: hidden;
    border-radius: 5px;
    .top {
      display: flex;
      padding-top: 20px;
      .top-card {
        padding: 10px;
        border: 1px solid rgb(235, 235, 235);
        border-radius: 4px;
        .fa-wrap{
          width: 50px;
          height: 50px;
          text-align: center;
          border-radius: 50%;
          text-align: 50px;
          margin-top: 7px;
          padding-top: 8px;
          .fa {
            font-size: 35px;
          }
        }
        .top-card-content {
          margin-top: 10px;
          p {
            margin: 0;
            text-align: center;
            text-indent: 0;
          }
          p.card-num {
            font-size: 20px;
            text-align: center;
            padding: 6px 0;
          }
        }
      }
    }

    .bottom {
      margin-top: 24px;
      .left {
        border: 1px solid rgb(235, 235, 235);
        border-radius: 4px;
        #main{
          width: 100%;
          height: 400px;
          padding-top: 20px;
        }
      };
      .right {
        height: 521px;
        border: 1px solid rgb(235, 235, 235);
        border-radius: 4px;
        .el-row {
          padding: 0 20px;
          .el-col {
            padding: 14px 0;
            height: 46px;
            &:last-child {
              padding-left: 48px;
            }
          }
        }
      }
    }
  }

</style>

<style>
  .bottom .right .topInfo .el-table--enable-row-hover .el-table__body tr:hover>td {
    background-color: #fff !important;
  }
  .bottom .right .topInfo .el-table .cell{
    padding: 0 !important;
  }
  .bottom .right .topInfo .el-table::after {
    width: 0 !important;
  }
  .mytablecol{
    padding-right: 5px;
  }
</style>

<template :is="view"
					transition="fade"
					transition-mode="out-in">

	<div class="page-wrapper">
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '/index' }">概览</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="page-container">
			<el-row class="top" :gutter="25">
			  <el-col class='top-item'>
			    <el-row class="top-card">
			      <el-col :span="8" :md="10">
			        <div class="fa-wrap" style="background-color: rgb(73,169,238)">
			          <i class="fa fa-database fa-inverse"></i>
			        </div>
			      </el-col>
			      <el-col :span="16" :md="14">
			        <div class="top-card-content card-font">
			          <p>设备总量（台）</p>
			          <p class="card-num">{{totalCount.device  }}</p>
			        </div>
			      </el-col>
			    </el-row>
			  </el-col>
        <el-col class='top-item'>
          <el-row class="top-card">
            <el-col :span="8" :md="10">
              <div class="fa-wrap" style="background-color: rgb(152, 216, 125)">
                <i v-if="isAdmin" class="fa fa-bandcamp fa-inverse"></i>
                <i v-else class="fa fa-thumbs-up fa-inverse"></i>
              </div>
            </el-col>
            <el-col :span="16" :md="14">
              <div class="top-card-content card-font">
                <p>{{isAdmin ? '品牌商 （人）' : '销售总量 （台）'}}</p>
                <p class="card-num">{{totalCount.proxyp || 0  }}</p>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col class='top-item'>
          <el-row class="top-card">
            <el-col :span="8" :md="10">
              <div class="fa-wrap" style="background-color: rgb(225,216,110)">
                <!-- <i v-if="isAdmin" class="fa fa-user fa-inverse"></i> -->
                <i class="fa fa-check fa-inverse"></i>
              </div>
            </el-col>
            <el-col :span="16" :md="14">
              <div class="top-card-content card-font">
                <!-- <p>{{isAdmin?'在线数量 （台）':'经销商（人）'}}</p> -->
                <p>经销商（人）</p>
                <p class="card-num">{{ totalCount.proxyj || 0  }}</p>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col class='top-item'>
          <el-row class="top-card">
            <el-col :span="8" :md="10">
              <div class="fa-wrap" style="background-color: rgb(243,133,124)">
                <i class="fa fa-users fa-inverse"></i>
              </div>
            </el-col>
            <el-col :span="16" :md="14">
              <div class="top-card-content card-font">
                <p>{{ isAdmin?'总用户数（人）' : '普通用户（人）' }}</p>
                <p class="card-num">{{totalCount.user  }}</p>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col class='top-item'>
          <el-row class="top-card">
            <el-col :span="8" :md="10">
              <div class="fa-wrap" style="background-color: rgb(243,133,124)">
                <i class="fa fa-check fa-inverse"></i>
              </div>
            </el-col>
            <el-col :span="16" :md="14">
              <div class="top-card-content card-font">
                <p>在线设备（台）</p>
                <p class="card-num">{{totalCount.online  }}</p>
              </div>
            </el-col>
          </el-row>
        </el-col>
			</el-row>

      <el-row class="bottom" :gutter="25">
        <el-col :span="15" :gutter="20">
          <div class="left">
            <div class="title">
              <span>增长趋势</span>
            </div>
            <div id="charts" style="position: relative;">
              <p class="card-font">设备平均增长&nbsp;&nbsp;<span class="font-24">{{ this.deviceAvg }}/天</span></p>
              <p class="card-font">用户平均增长&nbsp;&nbsp;<span class="font-24">{{ this.userAvg }}/天</span></p>
              <div class="block" style="position: absolute; top: 10px; right: 30px;">
                <el-date-picker
                  v-model="week"
                  style="width:110%;"
                  disabledDate="disableOver"
                  :clearable="false"
                  :picker-options="pickerOptions"
                  type="week"
                  @change="getInitTime()"
                  format="yyyy 第 WW 周"
                  placeholder="选择周">
                </el-date-picker>
              </div>
              <div id="main"></div>
            </div>
            <!-- <div class="showTip" v-show="showTip">没有查询到相关数据...</div> -->
          </div>
        </el-col>

        <el-col :span="9">
          <div class="right">
            <div class="title">
              <span>设备占比排名</span>
            </div>
            <el-row class="card-font first-row" style="font-size:14px;">
              <el-col style="width:55px;">排名</el-col>
              <el-col style="width:150px;">设备</el-col>
              <el-col style="width:30px;">占比</el-col>
              <el-col style="width:30px;">&nbsp;</el-col>
            </el-row>
            <div class="topInfo" style="height: 380px; overflow-y:auto;padding: 0 20px;">
              <el-table :data="devicesUserTopList" class="list-table" :show-header="false" style="border-left:none;">
                <el-table-column align="left" label="排名" width="40">
                  <template slot-scope="scope">
                    <span style="margin-left: 2px;">{{scope.$index + 1}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="产品" :show-overflow-tooltip="true" width="150">
                </el-table-column>
                <el-table-column label="占比" width="55">
                  <template slot-scope="scope">
                    <span>{{ scope.row.percentValue.toFixed(2)+'%' }}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="">
                  <template slot-scope="scope">
                    <progress style="margin-left: -5px;" max="100" :value="scope.row.percentValue.toFixed(2)"></progress>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>
		</div>
	</div>
</template>

<script>

	import {findDeviceUsePercent, getThisWeekAdd, findDeviceAddByTime, getDeviceById} from "../services/device";
  import {findUserAddByTime, getStaticsTotal} from "../services/user";
	import echarts  from "echarts";
  import Numeral from 'numeral';
  import Moment from 'moment';
	import {getTelemetersByDeviceId} from "../services/telemeter";
	import {getKeepAlarmsByDevice} from "../services/alarm";
	import {mapGetters, mapActions} from "vuex";
	// import MarkerClusterer from "../utils/baidu/MarkerClusterer";
	// let BMapLib = MarkerClusterer.BMapLib;
	import moment from 'moment';
	export default {
		data() {
			return {
        isAdmin: false,
        showTip: false,
        deviceAvg: 0,
        userAvg: 0,
        week: '',
        timeLine: [],
        userAddCountList: [],
        deviceAddCountList: [],
        devicesUserTopList: [],
        pickerOptions: {
          firstDayOfWeek: 1
        },
        thisWeekAdd: {
          addNumber: '',
          compareStatus: false,
          percent: ''
        },
        totalCount: {},
			}
		},
    created() {
      this.checkRole();
      this.getTotalCount();
      // this.getThisWeekAddData();
      this.getDevicesUsePercentTop();
      this.getInitTime();
    },
    mounted() {
      this.initCharts();
    },
    computed: {
      ...mapGetters(
        {user: 'userInfo'}
      )
    },
    methods: {
      // updateDate() {
      //   const month = this.week.getMonth() + 1;
      //   const date = this.week.getDate();
      //   //if(month.length ===)
      //   let time = new Date(this.week.getTime()+7 * 24* 3600 * 1000);
      //   const month1 = time.getMonth()+1; 
      //   const date1 = time.getDate();
      //   console.log(time)
      //   console.log(month);
      //   return 'yyyy 第 WW 周  ('+month+'-'+date+' 至 '+month1+'-'+date1+')'
      // },

      //判断角色
      checkRole() {
        const role = this.user.role;
        console.log(role)
        if (/admin/.test(role)) {
          this.isAdmin = true;
        }
      },
      // 获取新增设备相关数据 
      async getTotalCount() {
        const res = await getStaticsTotal();
        if (res && res.errorCode === 0) {
          this.totalCount = res.data;
          //console.log(this.totalCount)
        }
      },
      // 获取新增设备相关数据
      async getThisWeekAddData() {
        const thisWeekAddData = await getThisWeekAdd();
        if (thisWeekAddData && thisWeekAddData.errorCode === 0) {
          this.thisWeekAdd.addNumber = Numeral(thisWeekAddData.data).format('0,0');
        }
      },
      //获取设备占比
      async getDevicesUsePercentTop() {
        const devicesUseInfo = await findDeviceUsePercent();
        if (devicesUseInfo && devicesUseInfo.errorCode === 0) {
          this.devicesUserTopList = devicesUseInfo.data;
        }
      },
      /* 计算开始时间结束时间 */
      getInitTime(){
        //console.log(this.week)
        const thisWeekFirst = new Date(Moment().startOf('week'));
        const setDay = new Date(thisWeekFirst.getTime());
        if(!this.week){
          this.week = setDay;
        }else{
          this.week = new Date(this.week.getTime() - 24 * 60 * 60 * 1000);
        }
        this.startTime = this.week.getTime();
        this.endTime = this.startTime + 7 * 24 * 60 * 60 * 1000;
        this.getAddCountByTime();
      },
      /* 获取增长趋势 */
      async getAddCountByTime() {
        const deviceAddCount = await findDeviceAddByTime(this.startTime, this.endTime);
        const userAddCount = await findUserAddByTime(this.startTime, this.endTime);
        if (deviceAddCount && deviceAddCount.errorCode === 0) {
          let data = deviceAddCount.data || [];
          this.deviceAddCountList = [];
          this.timeLine = [];
          for(let i = 0; i < 7; i++){
            this.timeLine.push(Moment(this.week.getTime() + i * 24 * 60 * 60 * 1000).format('MM-DD'));
          }
          for (let i = 0; i < this.timeLine.length; i++) {
            let count = 0;
            for(let j = 0; j < data.length; j++){
              if(data[j].createTime){
                let day = data[j].createTime;
                day = Moment(day).format('MM-DD');
                if(day === this.timeLine[i]){
                  count = data[j].count;
                }
              }
            }
            this.deviceAddCountList.push(count);
          }
          this.chartOptions.xAxis[0].data = this.timeLine;
          this.chartOptions.series[0].data = this.deviceAddCountList;
        }
        if (userAddCount && userAddCount.errorCode === 0) {
          let data = userAddCount.data || [];
          this.userAddCountList = [];
          for (let i = 0; i < this.timeLine.length; i++) {
            let count = 0;
            for(let j = 0; j < data.length; j++){
              if(data[j].createTime){
                let day = data[j].createTime;
                day = Moment(day).format('MM-DD');
                if(day === this.timeLine[i]){
                  count += data[j].count;
                }
              }
            }
            this.userAddCountList.push(count);
          }
          let maxNum = this.compareNum(this.getTotal(this.deviceAddCountList),this.getTotal(this.userAddCountList));
          // console.log(maxNum);
          if(maxNum && maxNum < 5){
            this.chartOptions.yAxis[0].splitNumber = maxNum + 1;
            this.chartOptions.yAxis[0].max = maxNum + 1;
          }else{
            this.chartOptions.yAxis[0].splitNumber = 5;
            this.chartOptions.yAxis[0].max = null;
          }
          this.chartOptions.series[1].data = this.userAddCountList;
          if(this.getTotal(this.deviceAddCountList) === 0 && this.getTotal(this.userAddCountList) === 0) {
            // for(var i=0;i<7;i++) {
            //   this.deviceAddCountList = [8,8,8,8,8,8,8];
            //   this.userAddCountList = [8,8,8,8,8,8,8];
            // }
            // this.chartOptions.series[1].data = this.userAddCountList;
            // this.chartOptions.series[0].data = this.deviceAddCountList;
            this.myChart.setOption(this.chartOptions, true);
          } else {
            this.myChart.setOption(this.chartOptions, true)
          }
        }
        // console.log(this.getTotal(this.deviceAddCountList));
        // console.log(this.getTotal(this.userAddCountList));

        this.deviceAvg = Math.floor(this.getTotal(this.deviceAddCountList)/7);
        this.userAvg = Math.floor(this.getTotal(this.userAddCountList)/7);
      },
      /*计算总值*/
      getTotal(list){
        let count = 0;
        for(let i = 0; i < list.length; i ++){
          count += list[i];
        }
        return count;
      },
      compareNum(num1,num2){
        if(num1>num2){
          return num1;
        }else{
          return num2;
        }
      },
      initCharts() {
        this.myChart = echarts.init(document.getElementById('main'));
        this.chartOptions = {
          color: ["rgba(73, 169, 238, 1)", "rgba(152, 216, 125, 1)"],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            x: '480',
            itemHeight: '8',
            data: [
              {
                name: '设备',
                icon: 'circle'
              },
              {
                name: '用户',
                icon: 'circle'
              }
            ]
          },
          xAxis: [
            {
              type: 'category',
              data: this.timeLine,
              axisPointer: {
                type: 'none'
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              min: 0,
              axisLabel: {
                formatter: '{value} '
              }
            },

          ],
          series: [
            {
              name: '设备',
              type: 'bar',
              barWidth: '10',
              data: this.deviceAddCountList
            },
            {
              name: '用户',
              type: 'bar',
              barWidth: '10',
              data: this.userAddCountList
            },
          ]
        };
      }
    }


		
			
	}

</script>
